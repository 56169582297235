import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
			PlayFooty
			</title>
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://inkzstar-place.com/img/8133849.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<meta name={"msapplication-TileImage"} content={"https://inkzstar-place.com/img/8133849.png"} />
			<meta name={"msapplication-TileColor"} content={"https://inkzstar-place.com/img/8133849.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://inkzstar-place.com/img/11.jpg)"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="40%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Adres
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Cankurtaran, İshak Paşa Cd. No:6, 34122 Fatih/İstanbul, Türkiye
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email{" "}
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:contact@inkzstar-place.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									contact@inkzstar-place.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Telefon
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+905337385862
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="55%" padding="8px 8px 8px 8px" md-width="100%">
					<Box>
						<Box
							padding="56px 48px"
							margin="0 0 0 auto"
							md-max-width="100%"
							background="--color-primary"
							max-width="380px"
						>
							<Text as="h3" font="--headline3" margin="0 0 20px 0">
								Bizimle İletişime Geçin
							</Text>
							<Formspree endpoint="xeqpgrlv" completeText="İlginiz için teşekkür ederiz. Bir yönetici en kısa sürede sizinle iletişime geçecektir." errorMessage="Tüm alanların doldurulması gerekmektedir.">
								<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text margin="0 0 4px 0" font="--base">
												İsim
											</Text>
											<Input max-width="400px" width="100%" name="name" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Email
											</Text>
											<Input max-width="400px" width="100%" type="email" name="email" />
										</Box>
									</Box>
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Mesaj
											</Text>
											<Input width="100%" name="message" as="textarea" rows="4" />
										</Box>
									</Box>
									<Components.QuarklycommunityKitCheckbox>
										<Override slot="Text" font="12px/1.1 -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, sans-serif">
											İle tanıştım
											<Link href="/politika" text-decoration="none" hover-text-decoration="underline" color="--light">
												Gizlilik Politikası
											</Link>
											, verilerimin işleneceğini kabul ediyorum.
										</Override>
										<Override slot="Input" width="30px" margin="0px 10xp 0px 10px" />
									</Components.QuarklycommunityKitCheckbox>
									<Box width="100%" padding="8px 18px 8px 18px">
										<Box display="flex" flex-direction="column" align-items="flex-start">
											<Button background="--color-dark">
												Gönder
											</Button>
										</Box>
									</Box>
								</Box>
							</Formspree>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
	</Theme>;
});